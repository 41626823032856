/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    h3: "h3",
    code: "code",
    hr: "hr"
  }, _provideComponents(), props.components), {Type} = _components;
  if (!Type) _missingMdxReference("Type", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    id: "概要",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E6%A6%82%E8%A6%81",
    "aria-label": "概要 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), "概要"), "\n", React.createElement(_components.p, null, "Elementsは、HTMLで与えられたスライダーを形成する要素を収集、初期化するコンポーネントです。"), "\n", React.createElement(_components.h2, {
    id: "プロパティ",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3",
    "aria-label": "プロパティ permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), "プロパティ"), "\n", React.createElement(_components.h3, {
    id: "root",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#root",
    "aria-label": "root permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "root")), "\n", React.createElement(Type, {
    code: 'root: HTMLElement'
  }), "\n", React.createElement(_components.p, null, "ルート要素です。"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "slider",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#slider",
    "aria-label": "slider permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "slider")), "\n", React.createElement(Type, {
    code: 'slider: HTMLElement'
  }), "\n", React.createElement(_components.p, null, "スライダー要素です。任意の要素なので、", React.createElement(_components.code, null, "undefined"), "の可能性があります。"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "track",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#track",
    "aria-label": "track permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "track")), "\n", React.createElement(Type, {
    code: 'track: HTMLElement'
  }), "\n", React.createElement(_components.p, null, "トラック要素です。"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "list",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#list",
    "aria-label": "list permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "list")), "\n", React.createElement(Type, {
    code: 'list: HTMLElement'
  }), "\n", React.createElement(_components.p, null, "リスト要素です。"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "slides",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#slides",
    "aria-label": "slides permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "slides")), "\n", React.createElement(Type, {
    code: 'slides: HTMLElement[]'
  }), "\n", React.createElement(_components.p, null, "スライド要素を含んだ配列です。この配列にクローンは含まれていません。"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "arrows",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#arrows",
    "aria-label": "arrows permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "arrows")), "\n", React.createElement(Type, {
    code: 'arrows: HTMLElement'
  }), "\n", React.createElement(_components.p, null, "各矢印を内包している親要素で、与えられなかった場合は", React.createElement(_components.code, null, "undefined"), "です。"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "prev",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#prev",
    "aria-label": "prev permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "prev")), "\n", React.createElement(Type, {
    code: 'prev: HTMLButtonElement'
  }), "\n", React.createElement(_components.p, null, "前に戻るための矢印要素で、与えられなかった場合は", React.createElement(_components.code, null, "undefined"), "です。Arrowsコンポーネントが生成する矢印は含まれていません。"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "next",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#next",
    "aria-label": "next permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "next")), "\n", React.createElement(Type, {
    code: 'slider: HTMLButtonElement'
  }), "\n", React.createElement(_components.p, null, "次に進むための矢印要素で、与えられなかった場合は", React.createElement(_components.code, null, "undefined"), "です。Arrowsコンポーネントが生成する矢印は含まれていません。"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "bar",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#bar",
    "aria-label": "bar permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "bar")), "\n", React.createElement(Type, {
    code: 'slider: HTMLElement'
  }), "\n", React.createElement(_components.p, null, "自動再生用のバーの要素で、与えられなかった場合は", React.createElement(_components.code, null, "undefined"), "です。"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "play",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#play",
    "aria-label": "play permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "play")), "\n", React.createElement(Type, {
    code: 'play: HTMLElement'
  }), "\n", React.createElement(_components.p, null, "自動再生用の再生ボタンで、与えられなかった場合は", React.createElement(_components.code, null, "undefined"), "です。"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "pause",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#pause",
    "aria-label": "pause permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "pause")), "\n", React.createElement(Type, {
    code: 'pause: HTMLElement'
  }), "\n", React.createElement(_components.p, null, "自動再生用の停止ボタンで、与えられなかった場合は", React.createElement(_components.code, null, "undefined"), "です。"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
